/* eslint-disable @typescript-eslint/ban-ts-comment */
import { forwardRef, useCallback, useMemo } from 'react';
import { DynamicDataTemplate } from '../types';
import debounce from 'lodash/debounce';
import { addEditorMarkupToTemplate, removeEditorMarkupFromTemplate } from './editor-utils';
import { WysiwygEditor, type EditorRef } from '../../core/wysiwyg/wysiwyg';
import { Editor as HugeRTEEditor } from 'hugerte';

export type EditorProps = {
  contentCssUrl: string;
  initialVal: string;
  templates: DynamicDataTemplate[];
  onFocus: () => void;
  onChange: (val: string) => void;
};

export const EmailEditor = forwardRef<EditorRef, EditorProps>(
  ({ contentCssUrl, initialVal, templates, onChange: onChangeProp, onFocus }, forwardedRef) => {
    const onChange: (val: string, editor: HugeRTEEditor) => void = useCallback(
      debounce(
        (val: string) => {
          onChangeProp(removeEditorMarkupFromTemplate(val));
        },
        500,
        { trailing: true, leading: true },
      ),
      [onChangeProp],
    );

    const initialValWithMarkup = useMemo(
      () => (templates && initialVal?.length ? addEditorMarkupToTemplate(templates, initialVal) : ''),
      [templates, initialVal],
    );

    return !initialVal?.length || initialValWithMarkup ? (
      <WysiwygEditor
        ref={forwardedRef}
        data-testid="email-editor"
        initialValue={initialValWithMarkup}
        onEditorChange={onChange}
        onFocus={onFocus}
        init={{
          min_height: 500,
          content_css: contentCssUrl,
        }}
      />
    ) : null;
  },
);
