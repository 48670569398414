import { MdInfoOutline } from 'react-icons/md';
import { TextTooltip, TextTooltipProps } from './text-tooltip';
import { FC } from 'react';
import cx from 'classnames';

type Props = Omit<TextTooltipProps, 'children'> & {
  className?: string;
  tight?: boolean;
};

export const InfoTooltip: FC<Props> = ({ className, tight = false, ...props }) => (
  <TextTooltip {...props} asChild={true}>
    <div className={cx(tight && 'mx-4', className)}>
      <MdInfoOutline className={cx('text-[#426085] text-24')} />
    </div>
  </TextTooltip>
);
