import { Button, ButtonColor, ButtonPull, ButtonVariant } from '..';

export type ConfirmationModalConfiguration = {
  enabled: true;
  title: {
    on: string;
    off: string;
  };
  description: {
    on: string;
    off: string;
  };
  reassurance: {
    on: string;
    off: string;
  };
  label: string;
  continueButtonText: string;
  cancelButtonText: string;
};

type ConfirmationModalProps = {
  checked: boolean;
  configuration: ConfirmationModalConfiguration;
  onChange: (value: boolean) => void;
  setModalState: (value: React.SetStateAction<boolean>) => void;
};

export const SwitchConfirmationModal = ({
  checked,
  configuration,
  onChange,
  setModalState,
}: ConfirmationModalProps) => {
  const modalTitle = checked ? configuration.title.off : configuration.title.on;
  const modalDesc = (checked ? configuration.description.off : configuration.description.on).split('{{label}}');
  return (
    <div className="bg-white px-[35px] py-[17px] rounded-[4px]">
      <div className="text-[24px] font-bold" data-testid="confirmation-modal-title">
        {modalTitle}
      </div>
      <div className="my-[25px]">
        <div>
          {modalDesc[0]}
          <span className="font-bold">{configuration.label}</span>
          {modalDesc[1]}
        </div>
        <div>{checked ? configuration.reassurance.on : configuration.reassurance.off}</div>
      </div>
      <Button
        variant={ButtonVariant.Text}
        className="!text-pink-600"
        onClick={() => {
          setModalState(false);
        }}
      >
        {configuration.cancelButtonText}
      </Button>
      <Button
        color={ButtonColor.Primary}
        onClick={() => {
          onChange(!checked);
          setModalState(false);
        }}
        pull={ButtonPull.Right}
        data-testid="confirmation-modal-continue"
      >
        {configuration.continueButtonText}
      </Button>
    </div>
  );
};
