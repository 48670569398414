import classnames from 'classnames';
import { ForwardedRef, InputHTMLAttributes, ReactElement, forwardRef, useState } from 'react';
import { MaxCharsSpecProps, StyledMaxCharactersSpecification } from './styled-max-characters-specification';

type Props = {
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
} & InputHTMLAttributes<HTMLInputElement> &
  MaxCharsSpecProps;

export const StyledInput = forwardRef<HTMLInputElement, Props>(
  ({ className, includeMaxCharsSpec, onChange, ...rest }: Props, ref: ForwardedRef<HTMLInputElement>): ReactElement => {
    const [currentLength, setCurrentLength] = useState<number>(0);
    const { maxLength } = rest;

    const onChangeWrapper = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setCurrentLength(e.target.value.length || 0);
      onChange?.(e);
    };

    const props = {
      className: classnames('styled-input', className),
      ...rest,
      ...{ onChange: includeMaxCharsSpec ? onChangeWrapper : onChange },
      ref,
    };

    const input = <input {...props} />;

    return includeMaxCharsSpec ? (
      <div>
        {input}
        <StyledMaxCharactersSpecification currentLength={currentLength} maxLength={maxLength as number} />
      </div>
    ) : (
      input
    );
  },
);

export default StyledInput;
