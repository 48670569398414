import { useCallback, useEffect, useRef } from 'react';

type SomeFunction = (...args: any[]) => any;

export const useDebouncedCallback = <C extends SomeFunction>(callback: C, delay: number) => {
  const timeout = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = useCallback(
    (...args: any[]) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay],
  );

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return debouncedCallback;
};

export default useDebouncedCallback;
