import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { ButtonColor, ButtonSize } from '../../core/button/button';
import { CreateMediaFolderButton } from '../media-folders/create-media-folder-button';
import { MediaFolderBreadcrumb } from '../media-folders/media-folder-breadcrumb';
import { MediaFolderGrid } from '../media-folders/media-folder-grid';
import { CreateMediaItemButton } from '../media-items/create-media-item-button';
import { MediaItemDropzoneUpload } from '../media-items/media-item-dropzone-upload';
import { MediaItemGrid } from '../media-items/media-item-grid';
import { MediaItemPreviewModal } from '../media-items/media-item-preview-modal';
import { MediaBackendAdapter, MediaItemResponse } from '../types';

export type MediaManagerProps = {
  onInsertMediaItem?: (mediaItem: MediaItemResponse) => void;
  onInsertMediaItemError?: (error: string) => void;
  backendAdapter: MediaBackendAdapter;
};

export const MediaManager = ({ backendAdapter, onInsertMediaItem, onInsertMediaItemError }: MediaManagerProps) => {
  const { t } = useTranslation();
  const [selectedMediaItem, setSelectedMediaItem] = useState<MediaItemResponse>();
  const { currentFolder, parentFolders, subFolders, selectFolder, reload, loading } =
    backendAdapter.useFolderTraverse();
  const {
    mediaItems,
    loading: loadingMediaItems,
    reload: reloadMediaItems,
  } = backendAdapter.useMediaItemSearch({
    folderId: currentFolder?._id || null,
  });

  const onClickMediaItem = (item: MediaItemResponse) => {
    setSelectedMediaItem(item);
  };

  const onCloseMediaItem = () => {
    setSelectedMediaItem(undefined);
  };

  const { removeMediaItem } = backendAdapter.useRemoveMediaItem({});
  const onClickRemoveMedia = async (item: MediaItemResponse) => {
    await removeMediaItem({
      mediaId: item._id,
      folderId: item.folderId ?? undefined,
    });
    reloadMediaItems();
    onCloseMediaItem();
  };

  return (
    <div className="media-manager" data-testid="media-manager">
      <div className="header">
        <h2>{t('mediaManager.title')}</h2>
        <div className="actions">
          <CreateMediaFolderButton
            backendAdapter={backendAdapter}
            size={ButtonSize.Small}
            color={ButtonColor.Blue}
            parentId={currentFolder?._id || null}
            onFolderCreated={reload}
          >
            {t('mediaManager.createFolder')}
          </CreateMediaFolderButton>
          <CreateMediaItemButton
            backendAdapter={backendAdapter}
            size={ButtonSize.Small}
            color={ButtonColor.Blue}
            folderId={currentFolder?._id || null}
            onMediaItemCreated={reloadMediaItems}
            onMediaItemError={onInsertMediaItemError}
          >
            {t('mediaManager.upload')}
          </CreateMediaItemButton>
        </div>
      </div>
      <MediaFolderBreadcrumb currentFolder={currentFolder} parentFolders={parentFolders} onClickFolder={selectFolder} />

      {subFolders?.length ? <MediaFolderGrid folders={subFolders} onClickFolder={selectFolder} /> : null}

      <MediaItemDropzoneUpload backendAdapter={backendAdapter} folderId={currentFolder?._id || null}>
        {mediaItems?.length ? (
          <MediaItemGrid items={mediaItems} onClickMediaItem={onClickMediaItem} />
        ) : !loading && !loadingMediaItems && !mediaItems?.length && !subFolders?.length ? (
          <p>{t('mediaManager.noMedia')}</p>
        ) : null}

        {selectedMediaItem ? (
          <MediaItemPreviewModal
            item={selectedMediaItem}
            onClose={onCloseMediaItem}
            onInsertMediaItem={onInsertMediaItem ? () => onInsertMediaItem(selectedMediaItem) : undefined}
            onRemoveMediaItem={onClickRemoveMedia}
          />
        ) : null}
      </MediaItemDropzoneUpload>
    </div>
  );
};
