import { ReactElement } from 'react';
import { Trans } from 'react-i18next';

export type MaxCharsSpecProps =
  | {
      includeMaxCharsSpec?: never;
    }
  | {
      includeMaxCharsSpec: true;
      maxLength: number;
    };

type Props = {
  currentLength: number;
  maxLength: number;
};

export const StyledMaxCharactersSpecification = ({ currentLength, maxLength }: Props): ReactElement => {
  return (
    <div className="flex justify-between">
      <p className="mb-0 font-light text-gray-300 mt-[6px]">
        <Trans i18nKey="maxLengthSpec" values={{ maxLength }} />
      </p>
      <p className="mb-0 mt-[6px]">{currentLength}</p>
    </div>
  );
};

export default StyledMaxCharactersSpecification;
