import { isEqual } from 'lodash';
import { ParsedUrlQueryInput } from 'querystring';

// Builds a parsed query object from datagrid state, only including the properties that are different from default state
// The reason for this is to keep the url as concise as possible
export const buildDatagridUrlQuery = (
  state: Record<string, unknown>,
  defaultState: Record<string, unknown> = {},
): ParsedUrlQueryInput => {
  const query: ParsedUrlQueryInput = {};

  if (state.filter && !isEqual(state.filter, defaultState.filter)) {
    query.filter = JSON.stringify(state.filter);
  }

  if (state.paging && !isEqual(state.paging, defaultState.paging)) {
    query.paging = JSON.stringify(state.paging);
  }

  if (state.sort && !isEqual(state.sort, defaultState.sort)) {
    query.sort = JSON.stringify(state.sort);
  }

  return query;
};

// Builds a datagrid state object from a parsed query object, merging it with default state to ensure all properties are present
export const parseDatagridUrlQuery = (
  query: ParsedUrlQueryInput,
  defaultState: Record<string, unknown>,
): Record<string, unknown> => {
  const state: Record<string, unknown> = {};

  if (query.filter) {
    state.filter = JSON.parse(query.filter as string);
  } else {
    state.filter = defaultState.filter;
  }

  if (query.paging) {
    state.paging = JSON.parse(query.paging as string);
  } else {
    state.paging = defaultState.paging;
  }

  if (query.sort) {
    state.sort = JSON.parse(query.sort as string);
  } else {
    state.sort = defaultState.sort;
  }

  if (query.search) {
    state.search = query.search;
  } else {
    state.search = defaultState.search;
  }

  return state;
};

export const serializeDatagridState = (state: Record<string, unknown>): string => {
  const records = Object.entries(state).map(([key, value]) => {
    return `${key}=${encodeURIComponent(value as string)}`;
  });

  return records.join('&');
};
