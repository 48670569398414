import classnames from 'classnames';
import { ForwardedRef, LabelHTMLAttributes, PropsWithChildren, ReactElement, ReactNode, forwardRef } from 'react';
import { useTranslation } from 'next-i18next';
import { MdInfoOutline } from 'react-icons/md';
import { TextTooltip } from '../tooltip/text-tooltip';

type Props = {
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
  optional?: boolean;
  tooltip?: ReactNode;
} & Omit<PropsWithChildren & LabelHTMLAttributes<HTMLLabelElement>, 'content'>;

export const StyledLabel = forwardRef<HTMLLabelElement, Props>(
  (
    { children, className, optional = false, tooltip, ...rest }: Props,
    ref: ForwardedRef<HTMLLabelElement>,
  ): ReactElement => {
    const { t } = useTranslation();
    const cssClasses = classnames('styled-label', className);

    return (
      <label className={cssClasses} {...rest} ref={ref}>
        {children}
        {optional && <span className="styled-label__optional">{t('optional').toLowerCase()}</span>}
        {tooltip ? (
          <TextTooltip side="bottom" text={tooltip} asChild>
            <div>
              <MdInfoOutline color="#013a81" size={20} />
            </div>
          </TextTooltip>
        ) : null}
      </label>
    );
  },
);

export default StyledLabel;
