import React from 'react';
import * as RadixSwitch from '@radix-ui/react-switch';
import { Box } from '../layout/box/box';
import classnames from 'classnames';
import classNames from 'classnames';
import { Modal } from '../modal/modal';
import { SwitchConfirmationModal, ConfirmationModalConfiguration } from './switch-confirmation-modal';

export type SwitchProps = {
  id: string;
  className?: string;
  label?: string;
  title?: string;
  subLabel?: string | React.ReactNode;
  icon?: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
  hideSwitchThumbText?: boolean;
  confirmationConfig?: ConfirmationModalConfiguration;
};

export const Switch = ({
  icon,
  id,
  className,
  disabled,
  label,
  title,
  subLabel,
  onChange,
  checked,
  hideSwitchThumbText,
  confirmationConfig,
}: SwitchProps) => {
  const [showConfirmationModal, setShowConfirmationModal] = React.useState<boolean>(false);
  const onCheckedChange = !disabled
    ? confirmationConfig?.enabled
      ? () => setShowConfirmationModal(true)
      : onChange
    : undefined;

  return (
    <>
      <Box className={classnames('switch-container', className)}>
        <RadixSwitch.Root
          className="switch-root"
          id={id}
          name={id}
          checked={checked}
          onCheckedChange={onCheckedChange}
          title={title}
          disabled={disabled}
          data-testid={`switch-${id}`}
        >
          <RadixSwitch.Thumb
            className={classNames('switch-thumb', {
              'switch-thumb-no-text': hideSwitchThumbText,
            })}
          />
        </RadixSwitch.Root>
        {label || subLabel ? (
          <Box display="flex" flexDirection="column" className="switch-info">
            {label ? (
              <label className="label text-offblack" htmlFor={id}>
                {label}
              </label>
            ) : null}
            {subLabel ? (
              <label
                className={classNames('sublabel mb-0', {
                  'text-gray-600': disabled,
                  'text-offblack': !disabled,
                })}
                htmlFor={id}
              >
                {subLabel}
              </label>
            ) : null}
          </Box>
        ) : null}

        {icon ? <Box className="switch-icon">{icon}</Box> : null}
      </Box>
      {showConfirmationModal && confirmationConfig?.enabled && (
        <Modal open onClose={() => setShowConfirmationModal(false)} size="medium">
          <SwitchConfirmationModal
            checked={checked}
            configuration={confirmationConfig}
            onChange={onChange}
            setModalState={setShowConfirmationModal}
          />
        </Modal>
      )}
    </>
  );
};
