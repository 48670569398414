import classnames from 'classnames';
import { ForwardedRef, ReactElement, TextareaHTMLAttributes, forwardRef, useState } from 'react';
import { MaxCharsSpecProps, StyledMaxCharactersSpecification } from './styled-max-characters-specification';

type Props = {
  /**
   * We have to override the existing unwanted styles with a high specificity selector.
   * This means additional selector properties outside the current specificity need to be applied as '!important'.
   * Tailwind CSS classes need to by applied with the '!' prefix.
   */
  className?: string;
} & TextareaHTMLAttributes<HTMLTextAreaElement> &
  MaxCharsSpecProps;

export const StyledTextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { className, includeMaxCharsSpec, onChange, ...rest }: Props,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ): ReactElement => {
    const [currentLength, setCurrentLength] = useState<number>(0);
    const { maxLength } = rest;

    const onChangeWrapper = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
      setCurrentLength(e.target.value.length || 0);
      onChange?.(e);
    };

    const props = {
      className: classnames('styled-text-area', className),
      ...rest,
      ...{ onChange: includeMaxCharsSpec ? onChangeWrapper : onChange },
      ref,
    };

    const textArea = <textarea {...props} />;

    return includeMaxCharsSpec ? (
      <div>
        {textArea}
        <StyledMaxCharactersSpecification currentLength={currentLength} maxLength={maxLength as number} />
      </div>
    ) : (
      textArea
    );
  },
);

export default StyledTextArea;
