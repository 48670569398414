import * as RadixMenu from '@radix-ui/react-dropdown-menu';
import classnames from 'classnames';
import { FC } from 'react';

type ItemProps = {
  children: React.ReactNode;
  styled?: boolean;
  className?: string;
  disabled?: boolean;
  [key: string]: unknown;
};

export const DropdownMenuItem: FC<ItemProps> = ({
  children,
  className,
  styled = true,
  disabled,
  ...props
}: ItemProps) => {
  return (
    <RadixMenu.Item
      className={classnames(
        { 'dropdown-item': styled },
        className,
        `${disabled ? 'text-gray-600 pointer-events-none' : ''}`,
      )}
      {...props}
    >
      {children}
    </RadixMenu.Item>
  );
};
