import React, { useEffect } from 'react';
import { PreferenceSwitch } from './preference-switch';
import { FaSpinner } from 'react-icons/fa';
import { Card } from '../core/card/card';
import { ConfirmationModalConfiguration } from '../core/switch/switch-confirmation-modal';

export type Preference = {
  key: string;
  templateKey?: string;
  title: string;
  description: string | React.ReactNode;
  checked: boolean;
  custom?: boolean;
  disabled?: boolean;
  showActions?: boolean;
  showToggle?: boolean;
};

export type PreferenceGroup = {
  groupId: string;
  title: string;
  preferences: Preference[];
  loading?: boolean;
  actions?: React.ReactNode | React.ReactNode[];
};

export type PreferencesFormProps = {
  title?: string;
  description: string | React.ReactNode;
  actions?: React.ComponentType<{
    preference: Preference;
  }>;
  onChange?: (key: string) => (enabled: boolean) => Promise<void>;
  confirmationConfig?: ConfirmationModalConfiguration;
} & (
  | {
      grouped: true;
      preferences: PreferenceGroup[];
      groupHeader?: React.ComponentType<{
        title: string;
        groupId: string;
      }>;
    }
  | {
      grouped?: false;
      preferences: Preference[];
      groupHeader?: never;
    }
);

export const PreferencesForm = ({
  title,
  description,
  actions: ActionsComponent,
  grouped,
  groupHeader: GroupHeaderComponent,
  preferences,
  onChange,
  confirmationConfig,
}: PreferencesFormProps) => {
  useEffect((): void => {
    if (title) {
      document.title = `${title}`;
    }
  }, [title]);

  return (
    <>
      {title ? <h2 className="text-32 font-semibold text-offblack !mb-4">{title}</h2> : null}
      {description ? (
        typeof description === 'string' ? (
          <p className="text-16 font-normal !mb-2">{description}</p>
        ) : (
          description
        )
      ) : null}

      <div className="flex flex-col gap-12">
        {grouped ? (
          preferences.map(({ groupId, title, preferences, actions, loading }) => (
            <Card className="p-14 pb-18 flex flex-col gap-8" padded={false}>
              {GroupHeaderComponent ? (
                <GroupHeaderComponent title={title} groupId={groupId} />
              ) : (
                <div className="flex flex-row items-center justify-between">
                  <h3 className="m-0 font-light text-20">{title}</h3>

                  {actions}
                </div>
              )}

              {loading ? (
                <div className="flex flex-row items-center justify-center py-6">
                  <FaSpinner className="animate-spin text-gray-600 ml-2" />
                </div>
              ) : null}

              {preferences.map((a: Preference) => (
                <PreferenceSwitch
                  key={a.key}
                  id={a.key}
                  checked={a.checked}
                  title={a.title}
                  description={a.description}
                  onChange={onChange?.(a.key)}
                  disabled={a.disabled}
                  hideSwitch={a.showToggle === false}
                  actions={ActionsComponent && a.showActions ? <ActionsComponent preference={a} /> : null}
                  confirmationConfig={
                    confirmationConfig ? { ...confirmationConfig, label: a.title } : confirmationConfig
                  }
                />
              ))}
            </Card>
          ))
        ) : (
          <Card className="p-14 pb-18 flex flex-col gap-8" padded={false}>
            {preferences.map((a) => (
              <PreferenceSwitch
                key={a.key}
                id={a.key}
                checked={a.checked}
                title={a.title}
                description={a.description}
                onChange={onChange?.(a.key)}
                disabled={a.disabled}
                actions={ActionsComponent && a.showActions ? <ActionsComponent preference={a} /> : null}
                confirmationConfig={confirmationConfig ? { ...confirmationConfig, label: a.title } : confirmationConfig}
              />
            ))}
          </Card>
        )}
      </div>
    </>
  );
};
